var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RootContainer, ConfirmationServiceProvider, EntireLoader, ErrorDisplayer, Icon, NoticePopupContainer, RecommendedEventModal, RecommendedEventModalBody, AntPrimaryGhostButton, ScrollBar, TextComponent, useAccessToken, EntireBlur, StatusRenderer, MutateLoader } from 'renderer/components';
import { PATH_SIGNIN, PATH_SIGNUP, PATH_APP, PATH_TICKET_WITH_QR, PATH_TICKET_CHECK_MODAL, PATH_CUSTOMER, PATH_ADMIN, PATH_AUTH, LOCAL_STORAGE_KEY_ACCESS_TOKEN, PATH_APP_RESERVATION, PATH_APP_MY_STORE, PATH_APP_CUSTOMER, PATH_APP_PREPAID, PATH_APP_SALES, PATH_APP_SALES_EMPLOYEE, PATH_APP_SALES_STATISTICS, PATH_APP_USINGS_STATISTICS, PATH_APP_MESSAGES_TEMPLTES, PATH_APP_MESSAGES_HISTORY, PATH_APP_MARKETING, PATH_APP_MY, PATH_ADMIN_DASHBOARD, PATH_ADMIN_FRACHISES, PATH_ADMIN_INVOICES, PATH_ADMIN_MIGRATION, PATH_FRANCHISE_ADMIN, PATH_FRANCHISE_ADMIN_STORES, PATH_FRANCHISE_ADMIN_INVOICES, PATH_APP_VERIFICATION, PATH_APP_MULTILINK, NODE_ENV, APP_VERSION, PATH_SIGNOUT, SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN, PATH_APP_TRANSFERS, PATH_APP_STOCKS, PATH_APP_EXPENSES, } from 'renderer/constants';
import Button from 'antd/lib/button';
import loadable from '@loadable/component';
import { BugsnagService, TagManager, getSearchParams, loadIamportScripts, getWithExpiry, setWithExpiry, LocalStorage, gotoExternal, ChannelService, openWindow, useWindowSize, SessionStorage, WebviewInterface } from 'renderer/utils';
import 'renderer/locale/i18n';
import { CategoryQuery, ManagerQuery } from 'renderer/queries';
import { API_STAGE, PATH_SIGNIN_FORGOT_ID, PATH_SIGNIN_FORGOT_PASSWORD } from 'renderer/constants';
import { COLORS } from 'renderer/styles';
import { EntireLoaderProvider, EntireLoaderForProvider } from './components/utils/Loader';
import styled from 'styled-components';
import { IMAGES } from 'renderer/assets';
import { ErrorBoundary } from 'react-error-boundary';
import { ZIndexProvider } from './components/utils/ZIndexer';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import { RestClient } from './utils/RestClient';
import { ReservationCalendar } from './containers/app/Reservation/Calendar';
import { AdminDashboard } from './containers/admin/Dashboard';
import { FranchiseManagement } from './containers/admin/FranchiseManagement';
import { InvoiceManagement } from './containers/admin/InvoiceManagement';
import { MigrationHome } from './containers/admin/Migration';
import { useLocation, useNavigate } from 'react-router';
import { FranchiseInvoices, FranchiseStores } from './containers/franchise';
import { VerificationPhone } from './containers/app/VerificationPhone';
import { SignupForm } from 'renderer/containers/unauthorized/Signin';
import { AlimtalkManagement } from './containers/admin/ServiceMonitor';
import { EventTracker } from 'renderer/utils';
var fallback = {
    fallback: React.createElement(EntireLoader, { open: true })
};
function DebugObserver() {
    if (NODE_ENV !== 'production') {
        return null;
    }
    var snapshot = useRecoilSnapshot();
    React.useEffect(function () {
        console.debug('The following atoms were modified:' + snapshot);
        for (var _i = 0, _a = snapshot.getNodes_UNSTABLE({ isModified: true }); _i < _a.length; _i++) {
            var node = _a[_i];
            console.debug(node.key, snapshot.getLoadable(node));
        }
    }, [snapshot]);
    return null;
}
// TODO: electron-forge에서 code-split 사용시 오류 발생
var Signin = loadable(function () { return import('renderer/containers/unauthorized/Signin'); }, fallback);
var Signup = loadable(function () { return import('renderer/containers/unauthorized/Signup'); }, fallback);
var AccountFinder = loadable(function () { return import('renderer/containers/unauthorized/Finder'); }, fallback);
var App = loadable(function () { return import('renderer/containers/app/Home'); }, fallback);
var TicketWithQR = loadable(function () { return import('renderer/containers/external/TicketWithQR'); }, fallback);
var CustomerHome = loadable(function () { return import('renderer/containers/customer/CustomerHome'); }, fallback);
var TicketConfirmation = loadable(function () { return import('renderer/containers/TicketConfirmation'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var AdminHome = loadable(function () { return import('renderer/containers/admin/Home'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var FranchiseHome = loadable(function () { return import('renderer/containers/franchise/Home'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var UpdateIcon = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 40px;\n"], ["\n  width: 40px;\n"])));
// App
// TODO: electron-forge에서 code-split 사용시 오류 발생
var MarketingHome = loadable(function () { return import('renderer/containers/app/Marketing'); }, fallback);
var MessageHistory = loadable(function () { return import('renderer/containers/app/Message/MessageHistory'); }, fallback);
var SalesList = loadable(function () { return import('renderer/containers/app/Sales/SalesList'); }, fallback);
var StockHome = loadable(function () { return import('renderer/containers/app/Stocks/Home'); }, fallback);
var ExpensesHome = loadable(function () { return import('renderer/containers/app/Expenses/Home'); }, fallback);
var EmployeeSales = loadable(function () { return import('renderer/containers/app/Sales/EmployeeSales'); }, fallback);
var SalesStatistics = loadable(function () { return import('renderer/containers/app/Sales/SalesStatistics'); }, fallback);
var UsingStatistics = loadable(function () { return import('renderer/containers/app/Sales/UsingStatistics'); }, fallback);
var StoreManagementHome = loadable(function () { return import('renderer/containers/app/NewStore/Home'); }, fallback);
var CustomerHomeInApp = loadable(function () { return import('renderer/containers/app/Customer/Home'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var PrepaidHome = loadable(function () { return import('renderer/containers/app/Prepaid/Home'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var MyHome = loadable(function () { return import('renderer/containers/app/My/Home'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var MessageTemplates = loadable(function () { return import('renderer/containers/app/Message/MessageTemplates'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var MultiLinkAdmin = loadable(function () { return import('renderer/containers/app/MultiLink'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
var DepositHome = loadable(function () { return import('renderer/containers/app/Deposit/DepositHome'); }, {
    fallback: React.createElement(EntireLoader, { open: true })
});
export function ErrorFallback(_a) {
    var error = _a.error;
    var LOCAL_KYE = 'chunk_failed';
    var handleReload = function () {
        if (window && 'caches' in window && caches) {
            caches
                .keys()
                .then(function (names) {
                return Promise.all(names.map(caches.delete));
            })
                .then(function () {
                window.location.reload(false);
            });
        }
        else {
            window.location.reload(false);
        }
    };
    var isChunkError = function (message) {
        var chunkFailedMessage = /Loading chunk [\d]+ failed/;
        return message && chunkFailedMessage.test(message);
    };
    // Handle failed lazy loading of a JS/CSS chunk.
    React.useEffect(function () {
        if (isChunkError(error === null || error === void 0 ? void 0 : error.message)) {
            if (!getWithExpiry(LOCAL_KYE)) {
                setWithExpiry(LOCAL_KYE, 'true', 5000);
                setIsUpdating(true);
                handleReload();
            }
            else {
                setIsUpdating(false);
            }
        }
        else {
            setIsUpdating(false);
        }
        BugsnagService.getInstance().notify(error);
    }, [error]);
    var _b = React.useState(true), isUpdating = _b[0], setIsUpdating = _b[1];
    var isNeedUpdated = isChunkError(error.message);
    return isUpdating ? (React.createElement(EntireLoader, { open: true })) : (React.createElement(ErrorDisplayer, { isUpdated: isNeedUpdated, messages: API_STAGE === 'production'
            ? isNeedUpdated
                ? ['새로운 업데이트가 있습니다', '업데이트 버튼을 클릭해 업데이트해주세요.']
                : undefined
            : [error.message], icon: isNeedUpdated && React.createElement(UpdateIcon, { src: IMAGES.notification }), title: isNeedUpdated && 'Update', button: isNeedUpdated ? '업데이트' : '새로고침', buttonIcon: React.createElement(Icon, { name: "rotate", color: COLORS.primary1 }), onClick: handleReload }));
}
var Signout = function () {
    var signOut = useAccessToken().signOut;
    React.useEffect(function () {
        signOut(true);
    }, []);
    return React.createElement(React.Fragment, null);
};
var AdminOrAppRedirector = function () {
    var _a = useAccessToken(), accessToken = _a.accessToken, updateToken = _a.updateToken, isAutoLogin = _a.isAutoLogin;
    if (!accessToken) {
        return React.createElement(Navigate, { to: PATH_SIGNOUT });
    }
    else {
        RestClient.getInstance().setAccessToken(accessToken);
    }
    var _b = ManagerQuery.useGetMe({
        retryOnMount: false,
        enabled: !!accessToken,
        onSuccess: function (data) {
            var _a;
            var isSuperadmin = SessionStorage.get(SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN) && ((_a = data.manager) === null || _a === void 0 ? void 0 : _a.isSuperAdmin);
            if (!isSuperadmin) {
                updateToken(data.accessToken, data.refreshToken, isAutoLogin ? 'local' : 'session');
            }
        }
    }), isLoading = _b.isLoading, isError = _b.isError, data = _b.data, error = _b.error;
    if (isLoading) {
        return React.createElement(EntireLoader, { open: true });
    }
    if (isError) {
        return React.createElement(Navigate, { to: PATH_SIGNIN });
    }
    var manager = data === null || data === void 0 ? void 0 : data.manager;
    if (!manager) {
        React.createElement(Navigate, { to: PATH_AUTH });
    }
    return manager.isSuperAdmin ? (React.createElement(Navigate, { to: PATH_ADMIN })) : manager.isFranchiseAdmin ? (React.createElement(Navigate, { to: PATH_FRANCHISE_ADMIN })) : manager.storeId ? (React.createElement(Navigate, { to: PATH_APP })) : (React.createElement(Navigate, { to: PATH_SIGNUP }));
};
export function Application(props) {
    var _this = this;
    var navigate = useNavigate();
    var location = useLocation();
    var _a = React.useState(), maintenance = _a[0], setMaintenance = _a[1];
    React.useEffect(function () {
        TagManager.initTagManager();
        BugsnagService.getInstance().boot();
        loadIamportScripts();
        CategoryQuery.getVersions()
            .then(function (versions) {
            var _a, _b;
            if ((_a = versions === null || versions === void 0 ? void 0 : versions.server) === null || _a === void 0 ? void 0 : _a.maintenance) {
                setMaintenance((_b = versions.server) === null || _b === void 0 ? void 0 : _b.maintenance);
            }
        })
            .catch(function (error) { });
        initailizeRestClient(navigate);
        var initialSearch = props.initialSearch;
        if (initialSearch) {
            var params = getSearchParams(initialSearch);
            var redirect = params.get('redirect');
            var code = params.get('code');
            var event = params.get('event');
            if (redirect === '/signup') {
                if (code) {
                    navigate(redirect, { state: { params: { code: code } } });
                }
                else if (event) {
                    navigate(redirect, { state: { params: { event: event } } });
                }
            }
        }
        WebviewInterface.getInstance().notifyCompleteLoad();
    }, []);
    React.useEffect(function () {
        var _a;
        (_a = window._mfq) === null || _a === void 0 ? void 0 : _a.push(['newPageView', location.pathname]);
        EventTracker.send('Page View', location);
        ChannelService.getInstance().setPage(location.pathname);
    }, [location.pathname]);
    var isPC = useWindowSize().isPC;
    // FIXME: https://github.com/bvaughn/react-error-boundary/issues/111
    var ErrorBoundary1 = ErrorBoundary;
    return (React.createElement(ErrorBoundary1, { FallbackComponent: ErrorFallback },
        React.createElement(ZIndexProvider, null,
            React.createElement(RecoilRoot, { override: false },
                React.createElement(EntireLoaderProvider, null,
                    React.createElement(EntireLoaderForProvider, null),
                    React.createElement(MutateLoader, null),
                    React.createElement(ConfirmationServiceProvider, null,
                        React.createElement(StatusRenderer, { isPC: isPC }, !maintenance ? (React.createElement(RootContainer, null,
                            React.createElement(React.Fragment, null,
                                React.createElement(Routes, null,
                                    React.createElement(Route, { path: PATH_AUTH, element: React.createElement(AdminOrAppRedirector, null) }),
                                    React.createElement(Route, { path: PATH_SIGNOUT, element: React.createElement(Signout, null) }),
                                    React.createElement(Route, { path: PATH_APP, element: React.createElement(App, null) },
                                        React.createElement(Route, { path: "".concat(PATH_APP_RESERVATION, "/*"), element: React.createElement(ReservationCalendar, { isPC: isPC }) }),
                                        React.createElement(Route, { path: "".concat(PATH_APP_TRANSFERS, "/*"), element: React.createElement(DepositHome, null) }),
                                        React.createElement(Route, { path: "".concat(PATH_APP_MY_STORE, "/*"), element: React.createElement(StoreManagementHome, null) }),
                                        React.createElement(Route, { path: "".concat(PATH_APP_MY, "/*"), element: React.createElement(MyHome, null) }),
                                        React.createElement(Route, { path: PATH_APP_CUSTOMER, element: React.createElement(CustomerHomeInApp, null) }),
                                        React.createElement(Route, { path: PATH_APP_PREPAID, element: React.createElement(PrepaidHome, null) }),
                                        React.createElement(Route, { path: PATH_APP_SALES, element: React.createElement(SalesList, null) }),
                                        React.createElement(Route, { path: PATH_APP_STOCKS, element: React.createElement(StockHome, null) }),
                                        React.createElement(Route, { path: PATH_APP_EXPENSES, element: React.createElement(ExpensesHome, null) }),
                                        React.createElement(Route, { path: PATH_APP_SALES_EMPLOYEE, element: React.createElement(EmployeeSales, null) }),
                                        React.createElement(Route, { path: PATH_APP_SALES_STATISTICS, element: React.createElement(SalesStatistics, null) }),
                                        React.createElement(Route, { path: PATH_APP_USINGS_STATISTICS, element: React.createElement(UsingStatistics, null) }),
                                        React.createElement(Route, { path: PATH_APP_MESSAGES_TEMPLTES, element: React.createElement(MessageTemplates, null) }),
                                        React.createElement(Route, { path: PATH_APP_MESSAGES_HISTORY, element: React.createElement(MessageHistory, null) }),
                                        React.createElement(Route, { path: PATH_APP_MARKETING, element: React.createElement(MarketingHome, null) }),
                                        React.createElement(Route, { path: PATH_APP_MULTILINK, element: React.createElement(MultiLinkAdmin, null) }),
                                        React.createElement(Route, { path: PATH_APP_VERIFICATION, element: React.createElement(VerificationPhone, null) }),
                                        React.createElement(Route, { path: PATH_APP, element: React.createElement(Navigate, { to: PATH_APP_RESERVATION }) })),
                                    React.createElement(Route, { path: PATH_ADMIN, element: React.createElement(AdminHome, null) },
                                        React.createElement(Route, { path: PATH_ADMIN_DASHBOARD, element: React.createElement(AdminDashboard, null) }),
                                        React.createElement(Route, { path: 'alimtalk', element: React.createElement(AlimtalkManagement, null) }),
                                        React.createElement(Route, { path: PATH_ADMIN_FRACHISES, element: React.createElement(FranchiseManagement, null) }),
                                        React.createElement(Route, { path: PATH_ADMIN_INVOICES, element: React.createElement(InvoiceManagement, null) }),
                                        React.createElement(Route, { path: PATH_ADMIN_MIGRATION, element: React.createElement(MigrationHome, null) }),
                                        React.createElement(Route, { path: PATH_ADMIN, element: React.createElement(Navigate, { to: PATH_ADMIN_DASHBOARD }) })),
                                    React.createElement(Route, { path: PATH_FRANCHISE_ADMIN, element: React.createElement(FranchiseHome, null) },
                                        React.createElement(Route, { path: PATH_FRANCHISE_ADMIN_STORES, element: React.createElement(FranchiseStores, null) }),
                                        React.createElement(Route, { path: PATH_FRANCHISE_ADMIN_INVOICES, element: React.createElement(FranchiseInvoices, null) }),
                                        React.createElement(Route, { path: PATH_FRANCHISE_ADMIN, element: React.createElement(Navigate, { to: PATH_FRANCHISE_ADMIN_STORES }) })),
                                    React.createElement(Route, { path: PATH_TICKET_WITH_QR, element: React.createElement(TicketWithQR, null) }),
                                    React.createElement(Route, { path: PATH_SIGNUP, element: React.createElement(Signup, null) }),
                                    React.createElement(Route, { path: PATH_SIGNIN, element: React.createElement(Signin, null) },
                                        React.createElement(Route, { path: PATH_SIGNIN, element: React.createElement(SignupForm, null) }),
                                        React.createElement(Route, { path: PATH_SIGNIN_FORGOT_ID, element: React.createElement(AccountFinder, { type: "id" }) }),
                                        React.createElement(Route, { path: PATH_SIGNIN_FORGOT_PASSWORD, element: React.createElement(AccountFinder, { type: "password" }) })),
                                    React.createElement(Route, { path: PATH_CUSTOMER, element: React.createElement(CustomerHome, null) }),
                                    React.createElement(Route, { path: PATH_CUSTOMER, element: React.createElement(Navigate, { to: PATH_AUTH }) }),
                                    React.createElement(Route, { path: PATH_TICKET_CHECK_MODAL, element: React.createElement(TicketConfirmation, null) }),
                                    React.createElement(Route, { path: "*", element: React.createElement(ErrorDisplayer, { isUpdated: false, title: "404", messages: ['페이지를 찾을 수 없습니다.', '올바른 URL을 입력해주세요.'], button: "\uD648\uC73C\uB85C \uC774\uB3D9", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                                return __generator(this, function (_a) {
                                                    gotoExternal('https://crm.thinkofyou.kr');
                                                    return [2 /*return*/];
                                                });
                                            }); } }) }),
                                    React.createElement(Route, { path: "/", element: React.createElement(Navigate, { to: PATH_AUTH }) })),
                                React.createElement(NoticePopupContainer, null),
                                React.createElement(ThinkofyouWebUpdater, null)))) : (React.createElement(EntireBlur, { backgroundColor: COLORS.white, children: React.createElement("div", { dangerouslySetInnerHTML: { __html: maintenance } }) })))))))));
}
var initailizeRestClient = function (navigate) {
    var accessToken = LocalStorage.get(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
    if (accessToken) {
        RestClient.getInstance().setAccessToken(accessToken);
    }
    RestClient.getInstance().setErrorHandler(function (error) {
        var _a, _b, _c;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 410) {
            throw new Error('중복 요청입니다. 최초 요청만 반영됩니다.');
        }
        else {
            // console.group('error', error);
            if (error.response) {
                throw new Error((_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || '연결에러 발생';
            }
        }
    });
};
var ThinkofyouWebUpdater = function () {
    var data = CategoryQuery.useAppVeresion().data;
    var isPC = useWindowSize().isPC;
    var handleClose = function () { };
    var handleUpdate = function () {
        if ('caches' in window) {
            caches.keys().then(function (names) {
                // Delete all the cache files
                names.forEach(function (name) {
                    caches.delete(name);
                });
            });
        }
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.href = "".concat(window.location.origin, "?_t=").concat(Date.now());
    };
    var versionData = isPC ? data === null || data === void 0 ? void 0 : data.pc : data === null || data === void 0 ? void 0 : data.mobile;
    var isAvailable = versionData === null || versionData === void 0 ? void 0 : versionData.available.find(function (item) { return item === APP_VERSION; });
    return versionData ? ((versionData === null || versionData === void 0 ? void 0 : versionData.maintenance) ? (React.createElement(EntireBlur, { backgroundColor: COLORS.white, children: React.createElement("div", { dangerouslySetInnerHTML: { __html: versionData === null || versionData === void 0 ? void 0 : versionData.maintenance } }) })) : (React.createElement(RecommendedEventModal, { zIndex: 999999999, open: !isAvailable, footer: null, centered: true, onCancel: handleClose, closable: false, children: React.createElement(RecommendedEventModalBody, null,
            React.createElement(ScrollBar, { style: { maxHeight: '80vh' }, options: { scrollbars: { autoHide: 'never' }, overflowBehavior: { x: 'hidden' } } },
                React.createElement("img", { src: IMAGES.update_popup_image, style: { width: '100%' } }),
                React.createElement("div", { className: "content" },
                    React.createElement(TextComponent, { children: "v".concat(versionData.lastest, " \uC5C5\uB370\uC774\uD2B8 \uC548\uB0B4"), type: "headline1", marginBottom: 10 }),
                    React.createElement("p", null, "\uC0C8\uB85C\uC6B4 \uC5C5\uB370\uC774\uD2B8\uAC00 \uC788\uC2B5\uB2C8\uB2E4. \uC548\uC815\uC801\uC778 \uC0AC\uC6A9\uC744 \uC704\uD574 \uCD5C\uC2E0\uBC84\uC804\uC73C\uB85C \uC5C5\uB370\uC774\uD2B8 \uD6C4 \uC774\uC6A9\uD574\uC8FC\uC138\uC694."),
                    React.createElement(TextComponent, { children: "\uC5C5\uB370\uC774\uD2B8 \uBC29\uBC95", type: "headline3", marginBottom: 10, marginTop: 10 }),
                    React.createElement("p", null, "1. \uD558\uB2E8\uC758 '\uC5C5\uB370\uC774\uD2B8 \uD558\uAE30' \uBC84\uD2BC\uC744 \uD074\uB9AD\uD574\uC8FC\uC138\uC694."),
                    React.createElement("p", null, "2. 1\uBC88 \uBC29\uBC95\uC73C\uB85C \uC5C5\uB370\uC774\uD2B8\uAC00 \uC548\uB41C\uB2E4\uBA74?? 2-1 PC \uC774\uC6A9 \uACE0\uAC1D\uC740 \uC0C8\uB85C\uACE0\uCE68 \uD6C4 \uC774\uC6A9\uD574\uC8FC\uC138\uC694. 2-2 \uBAA8\uBC14\uC77C \uC774\uC6A9 \uACE0\uAC1D\uC740 \uC571\uC744 \uC644\uC804\uD788 \uAED0\uB2E4\uAC00 \uCF20 \uD6C4 \uC774\uC6A9\uD574\uC8FC\uC138\uC694. \uC6B0\uCE21"),
                    React.createElement("p", null,
                        "\uC0C1\uB2E8 \uD504\uB85C\uD544 \uBC84\uD2BC\uC744 \uD074\uB9AD\uD574 \uBC84\uC804\uC815\uBCF4\uAC00 ",
                        versionData.lastest,
                        "\uC73C\uB85C \uC5C5\uB370\uC774\uD2B8 \uB418\uC5C8\uB294\uC9C0 \uD655\uC778\uD574\uC8FC\uC138\uC694."),
                    React.createElement(TextComponent, { children: "\uD639\uC2DC, \uB354 \uAD81\uAE08\uD55C \uC810\uC774 \uC788\uC73C\uC2E0\uAC00\uC694?", type: "headline3", marginBottom: 10, marginTop: 10 }),
                    React.createElement("p", null, "\uC5C5\uB370\uC774\uD2B8 \uAD00\uB828 \uAD81\uAE08\uD55C \uC810\uC774 \uC788\uB2E4\uBA74 \uCC44\uD305\uC0C1\uB2F4\uC744 \uD1B5\uD574 \uD305\uCEE4\uBDF0 \uC6B4\uC601\uD300\uC5D0 \uBB38\uC758\uD574\uC8FC\uC138\uC694."))),
            React.createElement("div", { className: "content" },
                versionData.noticeURL ? (React.createElement(AntPrimaryGhostButton, { children: "\uC5C5\uB370\uC774\uD2B8 \uB0B4\uC6A9 \uC790\uC138\uD788 \uBCF4\uAE30", color: "primary", onClick: function () {
                        openWindow(versionData.noticeURL, '업데이트 내용 자세히 보기');
                    } })) : null,
                React.createElement(ApplyBetaButton, { children: "\uC5C5\uB370\uC774\uD2B8 \uD558\uAE30", color: "primary", type: "primary", onClick: function () { return handleUpdate(); } }))) }))) : null;
};
var ApplyBetaButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 0px !important;\n"], ["\n  margin-top: 0px !important;\n"])));
var templateObject_1, templateObject_2;
