var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// ActionSheetStyles.js
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ContentLoader } from 'renderer/components';
var Backdrop = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  opacity: ", ";\n  transition: opacity 1s ease;\n  z-index: 10;\n"], ["\n  display: ", ";\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  opacity: ", ";\n  transition: opacity 1s ease;\n  z-index: 10;\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'block' : 'none');
}, function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? '1' : '0');
});
var Sheet = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  transform: ", ";\n  transition: transform 0.4s ease-out;\n  opacity: ", ";\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  background: white;\n  padding-top: 9px;\n  z-index: 20;\n  box-shadow:\n    0px 2px 4px 0px rgba(0, 0, 0, 0.02),\n    0px 1px 6px -1px rgba(0, 0, 0, 0.02),\n    0px 1px 2px 0px rgba(0, 0, 0, 0.03);\n  border-radius: 8px 8px 0 0;\n"], ["\n  position: fixed;\n  transform: ", ";\n  transition: transform 0.4s ease-out;\n  opacity: ", ";\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  background: white;\n  padding-top: 9px;\n  z-index: 20;\n  box-shadow:\n    0px 2px 4px 0px rgba(0, 0, 0, 0.02),\n    0px 1px 6px -1px rgba(0, 0, 0, 0.02),\n    0px 1px 2px 0px rgba(0, 0, 0, 0.03);\n  border-radius: 8px 8px 0 0;\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'translateY(0)' : 'translateY(100%) translate3d(0, 0, 0)');
}, function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? '1' : '0');
});
export var ActionSheet = function (props) {
    var onClose = props.onClose, content = props.content, isOpen = props.isOpen;
    var _a = useState(false), contentLoaded = _a[0], setContentLoaded = _a[1];
    var toggleSheet = useCallback(function () {
        var newOpen = !isOpen;
        if (!newOpen) {
            setContentLoaded(false);
            onClose();
        }
        else {
            // Reset content loading state when reopening
        }
    }, [isOpen, onClose]);
    // Add a popstate event listener to handle back navigation
    React.useEffect(function () {
        var handleBackButton = function (event) {
            if (isOpen) {
                event.preventDefault();
                toggleSheet();
            }
        };
        window.addEventListener('popstate', handleBackButton);
        return function () { return window.removeEventListener('popstate', handleBackButton); };
    }, [isOpen, toggleSheet]);
    React.useEffect(function () {
        if (isOpen && !contentLoaded) {
            setTimeout(function () { return setContentLoaded(true); }, 10); // 시트가 올라간 후 콘텐츠 로딩
        }
    }, [isOpen]);
    return (React.createElement(React.Fragment, null,
        isOpen && React.createElement(Backdrop, { "$isOpen": isOpen, onClick: toggleSheet }),
        React.createElement(Sheet, { "$isOpen": isOpen }, !contentLoaded ? React.createElement(ContentLoader, null) : content)));
};
export default ActionSheet;
var templateObject_1, templateObject_2;
